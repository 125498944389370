<template>
  <div class="wrapper wrapper--login">
    <main class="main center" id="main">
      <placeholder dark />
    </main>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import placeholder from "@/components/placeholder";

export default {
  name: "Home",
  components: {
    Footer,
    placeholder,
  },
  data() {
    return {};
  },
};
</script>
